import CacheBuster from 'react-cache-buster';
import get from 'lodash/get';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { LicenseManager } from 'ag-grid-enterprise';
import Auth0Wrapper from './wrappers/Auth0Wrapper';
import ProviderWrapper from './wrappers/ProviderWrapper';
import Routes from './wrappers/Routes';
import UserDetailWrapper from './wrappers/UserDetailWrapper';
import Config from './infrastructure/config/config';
import BackDropSpinner from './components/spinner/BackDropSpinner';
import SnackBarWrapper from './wrappers/SnackBarWrapper';
import OrgUnitsProvider from './wrappers/OrgUnitsWrapper';
import ClickDisableWrapper, { ProcessingProvider } from './wrappers/ClickWrapper';

Config.initDataDog();
LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY || '');
const App = () => {
  const isProduction = Config.isProd();
  const allConfig = Config.getConfig();
  const version = get(allConfig, 'version');
  const googleClientId = get(allConfig, 'googleClientId') || '';
  return (
    <ProviderWrapper>
      <CacheBuster
        currentVersion={version}
        isEnabled={isProduction} // If false, the library is disabled.
        isVerboseMode={false} // If true, the library writes verbose logs to console.
        loadingComponent={<BackDropSpinner />} // If not pass, nothing appears at the time of new version check.
        metaFileDirectory="." // If public assets are hosted somewhere other than root on your server.
      >
        <Auth0Wrapper>
          <GoogleOAuthProvider clientId={googleClientId}>
            <SnackBarWrapper>
              <ProcessingProvider>
                <ClickDisableWrapper>
                  <UserDetailWrapper>
                    <OrgUnitsProvider>
                      <Routes />
                    </OrgUnitsProvider>
                  </UserDetailWrapper>
                </ClickDisableWrapper>
              </ProcessingProvider>
            </SnackBarWrapper>
          </GoogleOAuthProvider>
        </Auth0Wrapper>
      </CacheBuster>
    </ProviderWrapper>
  );
};

export default App;
