const styles = (theme: any) => ({
  renderContentBox: {
    flexGrow: 1,
  },
  pointerEvents: {
    pointerEvents: 'auto',
  },
  appBar: {
    boxShadow: 'none',
    height: '0px',
    backgroundColor: 'transparent',
    position: 'static',
    transition: theme.transitions.create(['height', 'margin-top'], {
      duration: theme.transitions.duration.shorter,
    }),
    pointerEvents: 'none',
    transform: 'translateY(25px)',
  },
  withDivAbove: {
    marginTop: '50px',
  },
  withoutDivAbove: {
    marginTop: '2px',
  },
  toolbar: {
    height: 1,
    px: { xs: 2, lg: 2 },
  },
});
export default styles;
