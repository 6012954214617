export const underscoreToSpaceFormatter = (text: string, titleCase = false): string => {
  if (!text) return '';
  const formattedText = text.replace(/_/g, ' ');
  if (titleCase) {
    return formattedText.replace(/\b\w/g, (char) => char.toUpperCase());
  }
  return formattedText;
};
export const spaceToUnderscoreFormatter = (repairStatus) => repairStatus.replace(/ /g, '_');

export const underscoreToHyphenFormatter = (repairStatus) => repairStatus?.replace(/_/g, '-');

export function currencyFormatter(currency: number | string | null, sign = '', decimals = 0): string {
  if (currency == null) return '';
  const formatted = parseFloat(currency.toString())
    .toFixed(decimals)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return `${sign}${formatted}`;
}
