import { EventSource } from 'eventsource';
import Config from '../infrastructure/config/config';

const appConfig = Config.getConfig();
const createEventSource = (endpoint: string, token: string): EventSource => {
  if (!token) {
    throw new Error('Authorization token not found.');
  }

  const url = new URL(endpoint, appConfig.BASE_URL).toString();

  return new EventSource(url, {
    fetch: (input, init) =>
      fetch(input, {
        ...init,
        headers: {
          ...(init?.headers || {}),
          Authorization: `Bearer ${token}`,
        },
      }),
  });
};

export default createEventSource;
