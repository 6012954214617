/* eslint-disable no-nested-ternary */
import React, { forwardRef, useEffect, useState, useImperativeHandle } from 'react';
import {
  Box,
  TextField,
  InputAdornment,
  CircularProgress,
  Typography,
  List,
  ListItem,
  ListItemText,
  Paper,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DevicesIcon from '@mui/icons-material/Devices';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { USERS, REPAIR_INHOUSE_DETAIL_ROUTE, REPAIR_TICKET_DETAILS_ROUTE, DEVICE_TO_INFO } from 'src/constants/routes';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { GlobalSearchResult, globalSearch } from 'src/api/globalSearch';
import { useTokenCallBack, useAppSelector } from 'src/store/hooks';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import globalSearchInputStyles from './globalSearchStyles';

interface GlobalSearchInputRef {
  focus: () => void;
}

const GlobalSearchInput = forwardRef<GlobalSearchInputRef>((_, ref) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<GlobalSearchResult[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchError, setSearchError] = useState<string | null>(null);
  const [showResults, setShowResults] = useState(false);
  const makeTokenCall = useTokenCallBack();
  const { accountDetails } = useAppSelector((state) => state.account);
  const styles = globalSearchInputStyles();
  const debounceTimeout = React.useRef<number | null>(null);
  const searchWrapperRef = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  // Expose focus method to parent
  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current?.focus();
    },
  }));

  const handleSearch = () => {
    if (searchTerm.length < 3) {
      setSearchResults([]);
      setShowResults(false);
      setSearchError(null);
      return;
    }

    setIsLoading(true);
    setSearchError(null);

    makeTokenCall(async (token: string) => {
      try {
        const accountId = accountDetails?.account_number;
        const response = await globalSearch({ accountId, token, searchTerm });
        setSearchResults(response.data.slice(0, 7));
        setShowResults(true);
      } catch (error) {
        setSearchError('An error occurred while searching.');
        setShowResults(true);
      } finally {
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = window.setTimeout(() => {
      handleSearch();
    }, 500);

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [searchTerm]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchWrapperRef.current && !searchWrapperRef.current.contains(event.target as Node)) {
        setShowResults(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleResultClick = (result: GlobalSearchResult) => {
    let url = '';

    if (result.record_type === 'user') {
      url = `${USERS}/${result.record_id}`;
    } else if (result.record_type === 'ticket') {
      if (result.repair_type === 'Vivacity') {
        url = `${REPAIR_TICKET_DETAILS_ROUTE}/${result.record_id}`;
      } else if (result.repair_type === 'In House') {
        url = `${REPAIR_INHOUSE_DETAIL_ROUTE}/${result.record_id}`;
      } else if (result.repair_type === 'Student') {
        url = `${REPAIR_TICKET_DETAILS_ROUTE}/${result.record_id}`;
      }
    } else if (result.record_type === 'device') {
      url = `${DEVICE_TO_INFO}${result.serial_number}`;
    }

    if (url) {
      window.location.href = url;
    }

    setShowResults(false);
    setSearchTerm('');
  };

  return (
    <Box sx={styles.searchWrapper} ref={searchWrapperRef}>
      <TextField
        placeholder="Search"
        value={searchTerm}
        onChange={handleInputChange}
        variant="outlined"
        fullWidth
        inputRef={inputRef}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {isLoading ? <CircularProgress size={20} /> : <SearchIcon />}
            </InputAdornment>
          ),
          endAdornment: searchTerm && (
            <InputAdornment position="end">
              <IconButton onClick={() => setSearchTerm('')}>
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={styles.textField}
      />
      {showResults && (
        <Paper sx={styles.resultsContainer}>
          {searchError ? (
            <Typography color="error" sx={styles.noResults}>
              {searchError}
            </Typography>
          ) : searchResults.length > 0 ? (
            <List>
              {searchResults.map((result) => (
                <ListItem button key={result.record_id} onClick={() => handleResultClick(result)}>
                  <ListItemText
                    primary={
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {result.record_type === 'device' && <DevicesIcon sx={{ color: '#575757', marginRight: 1 }} />}
                        {result.record_type === 'ticket' && <ReceiptIcon sx={{ color: '#575757', marginRight: 1 }} />}
                        {result.record_type === 'user' && (
                          <AccountCircleIcon sx={{ color: '#575757', marginRight: 1 }} />
                        )}
                        <Typography variant="body2" sx={styles.listItemText}>
                          {result.serial_number || result.ticket_number || result.full_name}
                        </Typography>
                      </Box>
                    }
                    secondary={result.email || result.asset_tag || result.repair_type}
                    primaryTypographyProps={{
                      variant: 'body2',
                      sx: { ...styles.listItemText, display: 'inline' },
                    }}
                    secondaryTypographyProps={{
                      variant: 'caption',
                      sx: styles.listItemText,
                    }}
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography sx={styles.noResults}>No results found</Typography>
          )}
        </Paper>
      )}
    </Box>
  );
});

export default GlobalSearchInput;
