import { alpha } from '@mui/material/styles';
import { COLORS } from '../../constants/colors/colors.constant';

const customStyles = (theme: any) => ({
  rootScrollbar: {
    flexGrow: 1,
    height: '100%',
    overflow: 'hidden',
    width: '355px',
  },
  scrollbar: {
    maxHeight: '100%',
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    scrollbarColor: `${alpha(theme.palette.grey[600], 0.48)} transparent`, // Firefox
    '&::-webkit-scrollbar': {
      width: '8px',
      height: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: alpha(theme.palette.grey[600], 0.48),
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: alpha(theme.palette.grey[600], 0.7),
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
  },
  mobileBox: {
    overflow: 'auto',
  },
  backgroundColor: {
    backgroundColor: COLORS.primary.backgroundGrey,
  },
});

export default customStyles;
