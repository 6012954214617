export const COLORS: Record<string, Record<string, string>> = {
  primary: {
    main: '#752aed', // Previously: #60269E
    purpleShade: '#914fc4',
    buttonLight: '#7F56D9',
    buttonDark: '#000000',
    textDark: '#FFFFFF',
    infoLight: '#51C6DB',
    infoBackground: '#DEF4F8',
    headerTitle: '#101828',
    headerSubText: '#3F4553',
    iconColor: '#6B737A',
    activeNavigationtext: '#2C3033',
    yellowGoogle: '#ffce44',
    orange: '#ff7e33',
    navigationText: '#667085',
    bgMain: '#F5F7F9',
    darkGrey: '#5B5B5B',
    lightgrey: '#888',
    red: '#E84B36',
    lightRed: '#FAE9E7',
    white: '#fefefe',
    darkBlue: '#667085',
    blue: '#1477AE',
    blueFiller: 'rgba(20, 119, 174, 0.2)',
    lightBlue: '#E0F3F3',
    black: '#000',
    green: '#13C750',
    greenFiller: 'rgba(19, 199, 80, 0.5)',
    lightGreen: '#E2F5EA',
    royalBlue: '#D0D0D9',
    blueGrey: '#3F4553',
    palePurple: '#EFE9F5',
    lightPurple: '#EAE5F2',
    offWhite: '#F9FAFB',
    grey: '#aaaaaa',
    lilac: '#f0e5ff',
    backgroundGrey: '#EFEEF1',
    textLightGrey: '#747D8E',
    textGrey: '#8A94A8',
    buttonGrey: '#DCE3EB',
    hex: '#9DA2A7',
    backButton: '#5B6780',
    cardText: '#747474',
    bgopacity: '#F0EAF6',
    Manatee: '#989EA8',
    Solitude: '#E7E9EC',
    Tints: '#B7BBBF',
    Mystic: '#D0D5DD',
    borderColor: '#EAECF0',
    listColor: '#DDD',
    listIcon: '#A9AEBA',
    background: '#E6E8EA',
    disabledGrey: '#00000042',
    disabledGreylight: '#0000001f',
    btnColor: '#ccc',
    bannerBackground: '#fffbe6',
  },
  secondary: {
    main: '#e3d4fb', // Previously the creditGreen: '#02A831'
    creditGreen: '#02A831',
    pacificBlue: '#009EBB',
    deepSkyBlue: '#01AEF0',
    iceClimber: '#1DDCD4',
    desaturatedCyan: '#699B98',
    pastelMagenta: '#F599C2',
    feastyFuchsia: '#ED008C',
    mellowMelon: '#F21C62',
    acaiJuice: '#91268E',
    lightBlueGray: '#f9fbfc',
    darkGray: '#7a7a7a',
    olive: '#837B00',
    mattGreen: '#3AB54A',
    spreadsheetGreen: '#027B12',
    liselotteSyrup: '#DD550B',
    threateningRed: '#BF040B',
    blood: '#7B0002',
    nuclearFallout: '#ACA000',
    springGrass: '#D6CF7F',
    gold: '#FED700',
    adriaticSea: '#015B7E',
    maastrichtBlue: '#00223D',
    amygdalaPurple: '#63025F',
    deepMagenta: '#9F005D',
    Gainsboro: '#D9D9D9',
    cardetBlue: '#A9AEBA',
    AteneoBlue: '#00416B',
    bubbles: '#E3F4FB',
    Linen: '#1BA7E3',
    honeyDew: '#ECFDF3',
    philippineGreen: '#027A48',
    mangoolia: '#F7F0FF',
    redChartLine: '#FF6384',
    blueChartLine: '#36A2EB',
    yellowChartLine: '#FFCE56',
    turquoiseChartLine: '#4BC0C0',
    violetChartLine: '#9966FF',
    orangeChartLine: '#FF9F40',
  },
  dashColors: {
    mediumorchid: '#BA55D3',
    mediumorchid1: '#E066FF',
    mediumorchid2: '#D15FEE',
    mediumorchid3: '#B452CD',
    mediumorchid4: '#7A378B',
    darkviolet: '#9400D3',
    darkorchid: '#9932CC',
    darkorchid1: '#BF3EFF',
    darkorchid2: '#B23AEE',
    darkorchid3: '#9A32CD',
    darkorchid4: '#68228B',
    indigo: '#4B0082',
    blueviolet: '#8A2BE2',
    purple1: '#9B30FF',
    purple2: '#912CEE',
    purple3: '#7D26CD',
    purple4: '#551A8B',
    mediumpurple: '#9370DB',
    mediumpurple1: '#AB82FF',
    mediumpurple2: '#9F79EE',
    mediumpurple3: '#8968CD',
    mediumpurple4: '#5D478B',
    darkslateblue: '#483D8B',
    lightslateblue: '#8470FF',
    mediumslateblue: '#7B68EE',
    slateblue: '#6A5ACD',
    slateblue1: '#836FFF',
    slateblue2: '#7A67EE',
    slateblue3: '#6959CD',
    slateblue4: '#473C8B',
    blue2: '#0000EE',
    blue3: '#0000CD',
    blue4: '#00008B',
    navy: '#000080',
    midnightblue: '#191970',
    cobalt: '#3D59AB',
    royalblue: '#4169E1',
    royalblue1: '#4876FF',
    royalblue2: '#436EEE',
    royalblue3: '#3A5FCD',
    royalblue4: '#27408B',
    cornflowerblue: '#6495ED',
    lightsteelblue: '#B0C4DE',
    lightsteelblue3: '#A2B5CD',
    lightsteelblue4: '#6E7B8B',
    slategray2: '#B9D3EE',
    slategray3: '#9FB6CD',
    dodgerblue1: '#1E90FF',
    dodgerblue2: '#1C86EE',
    dodgerblue3: '#1874CD',
    dodgerblue4: '#104E8B',
    steelblue: '#4682B4',
    steelblue1: '#63B8FF',
    steelblue2: '#5CACEE',
    steelblue3: '#4F94CD',
    steelblue4: '#36648B',
    lightskyblue: '#87CEFA',
    lightskyblue4: '#607B8B',
    skyblue1: '#87CEFF',
    skyblue2: '#7EC0EE',
    skyblue3: '#6CA6CD',
    skyblue4: '#4A708B',
    skyblue: '#87CEEB',
    deepskyblue1: '#00BFFF',
    deepskyblue2: '#00B2EE',
    deepskyblue3: '#009ACD',
    deepskyblue4: '#00688B',
    peacock: '#33A1C9',
    lightblue1: '#BFEFFF',
    lightblue4: '#68838B',
    powderblue: '#B0E0E6',
    cadetblue1: '#98F5FF',
    cadetblue2: '#8EE5EE',
    cadetblue3: '#7AC5CD',
    cadetblue4: '#53868B',
    turquoise1: '#00F5FF',
    turquoise2: '#00E5EE',
    turquoise3: '#00C5CD',
    turquoise4: '#00868B',
    cadetblue: '#5F9EA0',
    darkturquoise: '#00CED1',
    azure4: '#838B8B',
    lightcyan3: '#B4CDCD',
    lightcyan4: '#7A8B8B',
    paleturquoise1: '#BBFFFF',
    paleturquoise3: '#96CDCD',
    paleturquoise4: '#668B8B',
    darkslategray: '#2F4F4F',
    cyan: '#00FFFF',
    cyan2: '#00EEEE',
    cyan3: '#00CDCD',
    teal: '#008080',
    mediumturquoise: '#48D1CC',
    manganeseblue: '#03A89E',
    coldgrey: '#808A87',
    turquoiseblue: '#00C78C',
    aquamarine1: '#7FFFD4',
    aquamarine2: '#76EEC6',
    aquamarine3: '#66CDAA',
    aquamarine4: '#458B74',
    springgreen: '#00FF7F',
    springgreen1: '#00EE76',
    springgreen3: '#008B45',
    mediumseagreen: '#3CB371',
    seagreen3: '#43CD80',
    emeraldgreen: '#00C957',
    cobaltgreen: '#3D9140',
    darkseagreen1: '#C1FFC1',
    darkseagreen2: '#B4EEB4',
    darkseagreen3: '#9BCD9B',
    darkseagreen4: '#698B69',
    palegreen1: '#9AFF9A',
    palegreen3: '#7CCD7C',
    palegreen4: '#548B54',
    forestgreen: '#228B22',
    green1: '#00FF00',
    green2: '#00EE00',
    green3: '#00CD00',
    green4: '#008B00',
    darkgreen: '#006400',
    sapgreen: '#308014',
    lawngreen: '#7CFC00',
    chartreuse1: '#7FFF00',
    chartreuse2: '#76EE00',
    darkolivegreen3: '#A2CD5A',
    darkolivegreen4: '#6E8B3D',
    darkolivegreen: '#556B2F',
    olivedrab: '#6B8E23',
    olivedrab3: '#9ACD32',
    olivedrab4: '#698B22',
    lightyellow4: '#8B8B7A',
    yellow1: '#FFFF00',
    yellow3: '#CDCD00',
    yellow4: '#8B8B00',
    warmgrey: '#808069',
    olive: '#808000',
    darkkhaki: '#BDB76B',
    khaki4: '#8B864E',
    lemonchiffon4: '#8B8970',
    lightgoldenrod2: '#EEDC82',
    lightgoldenrod3: '#CDBE70',
    lightgoldenrod4: '#8B814C',
    banana: '#E3CF57',
    gold1: '#FFD700',
    gold2: '#EEC900',
    gold3: '#CDAD00',
    gold4: '#8B7500',
    goldenrod1: '#FFC125',
    goldenrod4: '#8B6914',
    darkgoldenrod: '#B8860B',
    darkgoldenrod1: '#FFB90F',
    darkgoldenrod3: '#CD950C',
    darkgoldenrod4: '#8B6508',
    orange1: '#FFA500',
    orange3: '#CD8500',
    navajowhite3: '#CDB38B',
    navajowhite4: '#8B795E',
    brick: '#9C661F',
    cadmiumyellow: '#FF9912',
    antiquewhite3: '#CDC0B0',
    antiquewhite4: '#8B8378',
    burlywood: '#DEB887',
    burlywood3: '#CDAA7D',
    burlywood4: '#8B7355',
    bisque3: '#CDB79E',
    bisque4: '#8B7D6B',
    melon: '#E3A869',
    darkorange: '#FF8C00',
    darkorange1: '#FF7F00',
    darkorange2: '#EE7600',
    darkorange3: '#CD6600',
    darkorange4: '#8B4500',
    orange: '#FF8000',
    tan1: '#FFA54F',
    tan2: '#EE9A49',
    tan3: '#CD853F',
    tan4: '#8B5A2B',
    peachpuff3: '#CDAF95',
    peachpuff4: '#8B7765',
    seashell4: '#8B8682',
    sandybrown: '#F4A460',
    rawsienna: '#C76114',
    chocolate2: '#EE7621',
    chocolate4: '#8B4513',
    ivoryblack: '#292421',
    flesh: '#FF7D40',
    burntsienna: '#8A360F',
    sienna2: '#EE7942',
    lightsalmon1: '#FFA07A',
    lightsalmon3: '#CD8162',
    coral: '#FF7F50',
    orangered1: '#FF4500',
    orangered2: '#EE4000',
    orangered3: '#CD3700',
    orangered4: '#8B2500',
    sepia: '#5E2612',
    darksalmon: '#E9967A',
    salmon1: '#FF8C69',
    salmon2: '#EE8262',
    salmon3: '#CD7054',
    salmon4: '#8B4C39',
    coral1: '#FF7256',
    coral2: '#EE6A50',
    coral3: '#CD5B45',
    coral4: '#8B3E2F',
    burntumber: '#8A3324',
    tomato1: '#FF6347',
    tomato2: '#EE5C42',
    tomato4: '#8B3626',
    salmon: '#FA8072',
    mistyrose4: '#8B7D7B',
    rosybrown4: '#8B6969',
    lightcoral: '#F08080',
    indianred: '#CD5C5C',
    indianred1: '#FF6A6A',
    indianred2: '#EE6363',
    indianred4: '#8B3A3A',
    indianred3: '#CD5555',
    brown: '#A52A2A',
    brown2: '#EE3B3B',
    brown3: '#CD3333',
    firebrick: '#B22222',
    firebrick1: '#FF3030',
    firebrick3: '#CD2626',
    firebrick4: '#8B1A1A',
    red2: '#EE0000',
    red3: '#CD0000',
    maroon: '#800000',

    indianr: '#B0171F',
    crimson: '#DC143C',
    lightpink4: '#8B5F65',
    pink2: '#EEA9B8',
    pink3: '#CD919E',
    pink4: '#8B636C',
    palevioletred: '#DB7093',
    palevioletred1: '#FF82AB',
    palevioletred2: '#EE799F',
    palevioletred3: '#CD6889',
    palevioletred4: '#8B475D',
    lavenderblush4: '#8B8386',
    violetred1: '#FF3E96',
    violetred2: '#EE3A8C',
    violetred3: '#CD3278',
    violetred4: '#8B2252',
    hotpink: '#FF69B4',
    hotpink1: '#FF6EB4',
    hotpink2: '#EE6AA7',
    hotpink3: '#CD6090',
    hotpink4: '#8B3A62',
    raspberry: '#872657',
    deeppink1: '#FF1493',
    deeppink2: '#EE1289',
    deeppink3: '#CD1076',
    deeppink4: '#8B0A50',
    maroon1: '#FF34B3',
    maroon2: '#EE30A7',
    maroon3: '#CD2990',
    maroon4: '#8B1C62',
    mediumvioletred: '#C71585',
    violetred: '#D02090',
    orchid: '#DA70D6',
    orchid1: '#FF83FA',
    orchid2: '#EE7AE9',
    orchid3: '#CD69C9',
    orchid4: '#8B4789',
    thistle4: '#8B7B8B',
    plum2: '#EEAEEE',
    plum3: '#CD96CD',
    plum4: '#8B668B',
    plum: '#DDA0DD',
    violet: '#EE82EE',
    magenta: '#FF00FF',
    magenta2: '#EE00EE',
    magenta3: '#CD00CD',
    magenta4: '#8B008B',
    purple: '#800080',
    sgib: '#8E388E',
    sgis: '#7171C6',
    sgil: '#7D9EC0',
    sgit: '#388E8E',
    sgic: '#71C671',
    sgio: '#8E8E38',
    sgig: '#848484',
    purpleShade: '#914fc4',
    textLight: '#60269F',
    button: '#60269C',
    buttonLight: '#7F56D9',
    infoLight: '#51C6DB',
    headerTitle: '#101828',
    blue: '#1477AE',
    black: '#000',
    green: '#13C750',
    blueGrey: '#3F4553',
    backButton: '#5B6780',
    cardText: '#747474',
    softTeal: '#C8D7D4',
    lightSilver: '#D3D3D3',
  },
};

export default COLORS;
