import { Theme } from '@mui/material';

const styles = (theme: Theme, isProcessing: boolean): any => ({
  wrapper: {
    position: 'relative',
  },
  childrenBox: {
    pointerEvents: isProcessing ? 'none' : 'auto',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.0)',
    zIndex: 99999999,
  },
});

export default styles;
