import { Alert, Snackbar } from '@mui/material';
import { createContext, useContext, useMemo, useState, ReactNode } from 'react';

interface SnackbarState {
  message: string;
  success?: boolean;
  open: boolean;
  severity?: 'success' | 'error' | 'warning' | 'info';
  autoHideDuration?: number;
  manual?: boolean;
}

interface SnackbarContextType {
  snack: SnackbarState;
  setSnackBar: React.Dispatch<React.SetStateAction<SnackbarState>>;
}

export const SnackbarContext = createContext<SnackbarContextType>({
  snack: { message: '', success: false, open: false },
  setSnackBar: () => null,
});

type Props = {
  children: ReactNode;
};

const SnackBarWrapper = ({ children }: Props) => {
  const [snack, setSnackBar] = useState<SnackbarState>({
    message: '',
    success: false,
    open: false,
    severity: 'success',
    autoHideDuration: 3000,
    manual: false,
  });

  const memo = useMemo(
    () => ({
      snack,
      setSnackBar,
    }),
    [snack]
  );

  const handleClose = () => {
    if (!snack.manual) {
      setSnackBar((prev) => ({
        ...prev,
        open: false,
      }));
    }
  };

  return (
    <SnackbarContext.Provider value={memo}>
      <Snackbar
        open={snack.open}
        autoHideDuration={snack.manual ? null : snack.autoHideDuration || 3000}
        onClose={handleClose}
      >
        <Alert severity={snack.severity || (snack.success ? 'success' : 'error')}>{snack.message}</Alert>
      </Snackbar>
      {children}
    </SnackbarContext.Provider>
  );
};

export const snackbarContext = () => useContext(SnackbarContext);

export default SnackBarWrapper;
