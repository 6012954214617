const formatDate = (dateTimeString: string | undefined, includeTime = false, useUTC = false): string => {
  if (!dateTimeString) {
    return 'N/A';
  }

  const date = new Date(dateTimeString);
  if (Number.isNaN(date.getTime())) {
    throw new Error('Invalid date input');
  }

  const year = useUTC ? date.getUTCFullYear() : date.getFullYear();
  const month = `0${useUTC ? date.getUTCMonth() + 1 : date.getMonth() + 1}`.slice(-2);
  const day = `0${useUTC ? date.getUTCDate() : date.getDate()}`.slice(-2);

  const formattedDate = `${month}-${day}-${year}`;

  if (includeTime) {
    const hours = `0${useUTC ? date.getUTCHours() : date.getHours()}`.slice(-2);
    const minutes = `0${useUTC ? date.getUTCMinutes() : date.getMinutes()}`.slice(-2);
    const seconds = `0${useUTC ? date.getUTCSeconds() : date.getSeconds()}`.slice(-2);

    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return `${formattedDate} ${formattedTime}`;
  }

  return formattedDate;
};

export default formatDate;
