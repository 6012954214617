import baseAPIFactory, { handleTokenHeaders } from './base';

export interface bookmark {
  bookmark_id: number;
  record_id: number;
  external_user_id: number;
  ticket_id: number;
  created_date: string;
  account_number: number;
  fleet_device_serial_number: string;
  fleet_device_asset_tag: string;
  fleet_device_base_warranty: number;
  fleet_device_base_warranty_start_date: string;
  fleet_device_base_warranty_end_date: string;
  fleet_device_advanced_warranty: number;
  fleet_device_advanced_warranty_start_date: string;
  fleet_device_advanced_warranty_end_date: string;
  fleet_device_device_name: string;
  repair_cost: string;
  ticket_number: string;
  repair_type: string;
  student_technician: string;
  failure_type: string;
  last_modified_date: string;
  complete_date: string;
  technician: string;
  internal_technician: string;
  internal_technician_full_name: string;
  repair_note: string;
  repair_status: string;
  last_modified_by: number;
  created_by: number;
  created_by_full_name: string;
  repair_box: number;
  fleet_device: number;
  closing_note: string;
  intake_note: string;
  chromebook_issue: string;
  tadabase_record_id: string;
  building_record_id: number;
  building_name: string;
  building_is_default: boolean;
  building_shipping_address: string;
  building_billing_address: string;
  builing_dashboard_color_primary: string;
  building_dashboard_color_secondary: string;
  building_image: string;
  building_phone: number;
  building_phone_extension: string;
  building_title: string;
  outbound_address: number;
  netsuite_record_id: number;
  warranty_status: string;
  outbound_box: number;
  outbound_tracking: string;
  ticket_creator_name: string;
  ticket_creator_email: string;
  student_id: string;
  student_full_name: string;
  closing_note_date: string;
  in_house_status: string;
  custom_data: string;
  credit_applied_date: string;
  credit_status: string;
}

export interface bookmarkParams {
  ticket_id: number;
}

const END_POINTS = {
  LIST_BOOKMARK: () => '/bookmark/all',
  ADD_BOOKMARK: () => '/bookmark',
  REMOVE_BOOKMARK: (recordId: number | string) => `/bookmark${recordId}`,
};
export const bookmarkList = async ({ token, ...params }: { token: string }) =>
  baseAPIFactory.get<bookmark[]>(END_POINTS.LIST_BOOKMARK(), {
    params,
    ...handleTokenHeaders({ token }),
  });

export const addBookmark = async ({ data, token }: { data: bookmarkParams; token: string }) =>
  baseAPIFactory.post<bookmark>(END_POINTS.ADD_BOOKMARK(), data, handleTokenHeaders({ token }));

export const removedBookmark = async ({ id, token }: { id: number | string; token: string }) =>
  baseAPIFactory.delete<bookmark>(END_POINTS.REMOVE_BOOKMARK(id), handleTokenHeaders({ token }));
