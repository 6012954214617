import baseAPIFactory, { handleTokenHeaders } from './base';

const END_POINTS = {
  FETCH_USER_PREFERENCES: (preferenceKey: string) => `app/user_preferences/${preferenceKey}`,
  CREATE_USER_PREFERENCES: (preferenceKey: string) => `app/user_preferences/${preferenceKey}`,
  UPDATE_USER_PREFERENCES: (preferenceKey: string) => `app/user_preferences/${preferenceKey}`,
};

export const fetchUserPreferences = async ({ preferenceKey, token }: { preferenceKey: string; token?: string }) =>
  baseAPIFactory.get(END_POINTS.FETCH_USER_PREFERENCES(preferenceKey), handleTokenHeaders({ token }));

export const createUserPreferences = async ({
  preferenceKey,
  preferenceValue,
  token,
}: {
  preferenceKey: string;
  preferenceValue: any;
  token: string;
}) =>
  baseAPIFactory.post(
    END_POINTS.CREATE_USER_PREFERENCES(preferenceKey),
    { preferenceValue },
    handleTokenHeaders({ token })
  );

export const updateUserPreferences = async ({
  preferenceKey,
  preferenceValue,
  token,
}: {
  preferenceKey: string;
  preferenceValue: any;
  token: string;
}) =>
  baseAPIFactory.put(
    END_POINTS.UPDATE_USER_PREFERENCES(preferenceKey),
    { preferenceValue },
    handleTokenHeaders({ token })
  );
