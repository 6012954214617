import baseAPIFactory, { handleTokenHeaders, TokenParams } from '../base';
import { TransformedQueryResult } from '../types/query';

export type FetchParamTypes = {
  token: string;
  page?: number | string;
  search_value?: string;
  search_field?: string;
};
export type FetchParamTypesNoPage = {
  token: string;
};

export type FetchParamTypesWithPage = {
  token?: string;
  page?: number;
  search_field?: string;
  search_value?: number | string;
  sort?: string;
  contains?: string;
};

export type FetchParamTypesWithPageAccount = {
  token?: string;
  page?: number;
  search_field?: string;
  search_value?: number | string;
  sort?: string;
  account: number;
  contains?: string;
};

export type AccountCreateInitialType = {
  full_name: string;
  title: string | null;
  email: string | null;
  phone: string | null;
  account: string;
  profile_image: any | null;
  user_status: string | null;
  user_role: string | null;
  in_house_repair: boolean | null;
  building: string | null;
} & TokenParams;

export interface AccountItem {
  role_name: string;
  record_id: number;
  full_name: string;
  title: null | string;
  email: string;
  phone: string;
  account: number;
  profile_image: null | string;
  user_status: UserStatus;
  user_role: number;
  in_house_repair: boolean;
  last_modified_date: Date;
  created_on: Date;
  last_modified_by: number | null;
  is_gac: boolean;
  two_factor_authentication: boolean;
  building: number | null;
  student_id: null;
  organizational_unit: null | string;
  r_building: RBuilding | null;
  external_user_role: ExternalUserRole;
}

export interface ExternalUserRole {
  record_id: number;
  role_name: string;
}
export interface RBuilding {
  record_id: number;
  building_name: string;
}

export enum UserStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export type AccountDetails = {
  account_number: number;
  account_name: string;
  phone_number: string;
  email: string;
  domain: string;
  credit_limit: string;
  account_balance: string;
  account_manager: number;
  account_site: number;
  territory: number;
  payment_terms: string | null;
  subscription_tier: string;
  background_logo?: string;
};

export type AccountBuilding = {
  record_id: number;
  building_name: string;
  building_image: string | null;
  phone: string | null;
  extension: string | null;
  default_building: boolean;
  dashboard_color: string | null;
  building_shipping_address: string;
  building_billing_address: string;
};

export type USER_PROFILE = {
  full_name: string;
  title: string;
  email: string;
  extension: string;
  phone: string;
  profile_image: any;
  user_status: string;
  user_role: number;
  account: number;
  created_by: number;
  last_modified_by: number;
  in_house_repair: boolean;
  token?: string;
};
export interface AccountData {
  account_name: string;
  phone_number: string;
  email: string;
  domain: string;
  credit_limit: number | null;
  account_credit: number | null;
  account_manager: number | null;
  account_site: number | null;
  in_house_repairs: boolean;
  territory: number | null;
  payment_terms: string;
  last_modified_by: string;
  tadabase_account_number: string | null;
  netsuite_id: number | null;
  account_paid_tier: string | null;
  subscription_tier: string | null;
  service_account_current_state: string;
}

export type twoFactorAuth = {
  token?: string;
};

const END_POINTS = {
  ACCOUNT_MANAGER: () => '/account/manager',
  FETCH_ALL_ACCOUNT_FOR_ADMIN: () => 'admin/account',
  GET_ACCOUNT_FOR_ADMIN: (accountNumber: number) => `admin/account/${accountNumber}`,
  FETCH_ALL_ACCOUNT: () => `/account/`,
  FETCH_ALL_ACCOUNT_NO_PAGE: () => `/account/getAll/noPage`,
  FETCH_ALL: (account: string | number) => `/account/contact/get_all/${account}`,
  CREATE_ACCOUNT: () => `/account/contact/`,
  DELETE_ITEM: (itemId: string | number | null) => `/account/contact/${itemId}`,
  GET_ACCOUNT_DETAILS: (emailAddress: string | null) => `/account/contact/account_details/${emailAddress}`,
  GET_ADDRESS_DETAILS: (itemId: string | number | null) => `account/building/${itemId}`,
  GET_DASHBOARD_DATA: (accountId: number | undefined) => `/account/${accountId}/dashboard`,
  GET_ITEM: (itemId: string | number | null) => `/account/contact/${itemId}`,
  TOGGLE_TWO_FACTOR_AUTH: () => '/user/toggle-mfa',
  UPDATE_ACCOUNT_CREDIT: (itemId: string | number | null) => `/account/update_account_credit/${itemId}`,
  UPDATE_ACCOUNT_IN_BULK: () => '/account/bulk',
  UPDATE_IMG_USER: (recordId?: string | number) => `/user/${recordId}`,
  UPDATE_ITEM: (itemId: string | number | null) => `/account/contact/${itemId}`,
  UPDATE_SUBSCRIPTION: (itemId: string | number | null) => `/account/update_subscription_tier/${itemId}`,
  UPDATE_USER: (recordId?: string | number) => `/account/contact/${recordId}`,
  START_TRIAL_TIER: (accountNumber: string | number) => `/account/start-trial/${accountNumber}`,
  FETCH_ALL_ACCOUNT_SUBSCRIPTION: () => `/account/admin/paidList`,
  FETCH_ALL_NOTIFICATION_TYPES: () => '/notification_type/',
  GET_USER_NOTIFICATION_PREFERENCES: (userId: number) => `/notification_preference/${userId}`,
  ADD_USER_NOTIFICATION_PREFERENCE: () => '/notification_preference/',
  REMOVE_USER_NOTIFICATION_PREFERENCE: (userId: number) => `/notification_preference/${userId}`,
  UPDATE_ACCOUNT_LOGO: () => `/account/background/logo`,
  ACCOUNT_FEATURE_KEYS: (accountNumber: number) => `/customer/${accountNumber}/account-feature-keys`,
};

export const getAccountDashboardData = async ({
  accountId,
  token,
}: {
  accountId: number | undefined;
  token?: string;
}) => baseAPIFactory.get(END_POINTS.GET_DASHBOARD_DATA(accountId), handleTokenHeaders({ token }));

export const getPaginatedList = async (props: FetchParamTypesWithPageAccount) => {
  const params: { [key: string]: any } = {};
  if (props.page) {
    params.page_no = props.page;
  }
  if (props.contains) {
    params.contains = props.contains;
  }
  if (props.search_field && props.search_value) {
    params.search_field = props.search_field;
    params.search_value = props.search_value;
  }
  return baseAPIFactory.get<AccountItem[]>(END_POINTS.FETCH_ALL(props.account), {
    params,
    ...handleTokenHeaders(props),
  });
};

export const getPaginatedAccountList = async (props: FetchParamTypes) => {
  const params: { [key: string]: any } = {};
  if (props.page) {
    params.page_no = props.page;
  }
  return baseAPIFactory.get<AccountDetails[]>(END_POINTS.FETCH_ALL_ACCOUNT(), { params, ...handleTokenHeaders(props) });
};

export const getAllAccountsForAdmin = async (props: FetchParamTypes) => {
  const params: { [key: string]: any } = {};
  return baseAPIFactory.get<any[]>(END_POINTS.FETCH_ALL_ACCOUNT_FOR_ADMIN(), {
    params,
    ...handleTokenHeaders(props),
  });
};

export const getAccount = async (accountNumber: number, token: string) =>
  baseAPIFactory.get<any>(END_POINTS.GET_ACCOUNT_FOR_ADMIN(accountNumber), handleTokenHeaders({ token }));

export const getDefaultBuilding = async ({ account, token }: { account: string | number; token: string }) =>
  baseAPIFactory.get<any>(`/account/building/default_building/${account}`, handleTokenHeaders({ token }));

export const getAllAccounts = async (props: FetchParamTypes) => {
  const params: { [key: string]: any } = {};
  if (props.page) {
    params.page_no = props.page;
  }
  if (props.search_field && props.search_value) {
    params.search_field = props.search_field;
    params.search_value = props.search_value;
  }
  return baseAPIFactory.get<AccountDetails[]>(END_POINTS.FETCH_ALL_ACCOUNT(), {
    params,
    ...handleTokenHeaders(props),
  });
};

export const updateAccountInBulk = async (users: AccountData[], props: TokenParams) => {
  const response = await baseAPIFactory.post(
    END_POINTS.UPDATE_ACCOUNT_IN_BULK(),
    { users },
    {
      ...handleTokenHeaders(props),
    }
  );
  return response.status;
};

export const getAllAccountsNoPage = async (token: string) =>
  baseAPIFactory.get<AccountDetails[]>(END_POINTS.FETCH_ALL_ACCOUNT_NO_PAGE(), handleTokenHeaders({ token }));

export const getAccountManager = async (token: string) =>
  baseAPIFactory.get(END_POINTS.ACCOUNT_MANAGER(), handleTokenHeaders({ token }));

export const createAdminAccount = async (data: any, token: string) =>
  baseAPIFactory.post(END_POINTS.FETCH_ALL_ACCOUNT(), data, handleTokenHeaders({ token }));

export const getAccountFeatureKeys = async (accountNumber: number, token: string) =>
  baseAPIFactory.get<string[]>(END_POINTS.ACCOUNT_FEATURE_KEYS(accountNumber), {
    ...handleTokenHeaders({ token }),
  });

export const createAccount = async (data: AccountCreateInitialType) =>
  baseAPIFactory.post(END_POINTS.CREATE_ACCOUNT(), data, handleTokenHeaders(data));

export const updateAccount = async (data: { recordId?: string | number; updateData: any }) => {
  const response = await baseAPIFactory.put(END_POINTS.UPDATE_USER(data?.recordId), data.updateData, {
    ...handleTokenHeaders(data.updateData),
  });

  return response;
};
export const updateAccountImg = async (data: { recordId?: string | number; updateData: any }) => {
  const formData = new FormData();
  formData.append('profile_image', data.updateData.uploadImage);
  const response = await baseAPIFactory.put(END_POINTS.UPDATE_IMG_USER(data?.recordId), formData, {
    ...handleTokenHeaders(data.updateData),
  });

  return response;
};

export const fetchAccountById = async ({ itemId, token }: { itemId: string | number | null; token?: string }) =>
  baseAPIFactory.get<AccountItem[]>(END_POINTS.GET_ITEM(itemId), handleTokenHeaders({ token }));

export const fetchAccountDetailsByEmailId = async ({
  emailAddress,
  token,
}: {
  emailAddress?: string | any;
  token?: string;
}) => baseAPIFactory.get<any>(END_POINTS.GET_ACCOUNT_DETAILS(emailAddress), handleTokenHeaders({ token }));

export const fetchAccountBuildingById = async ({ itemId, token }: { itemId: string | number | null; token?: string }) =>
  baseAPIFactory.get<AccountBuilding[]>(END_POINTS.GET_ADDRESS_DETAILS(itemId), handleTokenHeaders({ token }));

export const deleteAccountById = async (recordId: string | number | null, token: any) =>
  baseAPIFactory.delete(END_POINTS.DELETE_ITEM(recordId), handleTokenHeaders({ token }));

export const updateAccountById = async (recordId: string | number | null, data: any, token: any) => {
  baseAPIFactory.put(END_POINTS.UPDATE_ITEM(recordId), data, handleTokenHeaders({ token }));
};

export const updateSubscriptionById = async (recordId: string | number | null, data: any, token: any) => {
  baseAPIFactory.put(END_POINTS.UPDATE_SUBSCRIPTION(recordId), data, handleTokenHeaders({ token }));
};

export const updateAccountCById = async (recordId: string | number | null, data: any, token: any) => {
  baseAPIFactory.put(END_POINTS.UPDATE_ACCOUNT_CREDIT(recordId), data, handleTokenHeaders({ token }));
};

export const toggleTwoFactorAuth = async (data: twoFactorAuth) =>
  baseAPIFactory.post(END_POINTS.TOGGLE_TWO_FACTOR_AUTH(), {}, handleTokenHeaders(data));

export const startTrialTierAccount = async ({
  accountNumber,
  token,
}: {
  accountNumber: string | number;
  token?: string;
}) => baseAPIFactory.put(END_POINTS.START_TRIAL_TIER(accountNumber), {}, handleTokenHeaders({ token }));

export const getAdminSubscriptionList = async (params: any, token: string) =>
  baseAPIFactory.get<TransformedQueryResult>(END_POINTS.FETCH_ALL_ACCOUNT_SUBSCRIPTION(), {
    params,
    ...handleTokenHeaders({ token }),
  });
export const getAllNotificationTypes = async (token: string) => {
  const response = await baseAPIFactory.get(END_POINTS.FETCH_ALL_NOTIFICATION_TYPES(), handleTokenHeaders({ token }));
  return response;
};

export const getUserNotificationPreferences = async (userId: number, token: string) => {
  const response = await baseAPIFactory.get(
    END_POINTS.GET_USER_NOTIFICATION_PREFERENCES(userId),
    handleTokenHeaders({ token })
  );
  return response;
};

export const addUserNotificationPreferences = async (data: { userId: number; preferences: any[] }, token: string) => {
  const response = await baseAPIFactory.post(
    END_POINTS.ADD_USER_NOTIFICATION_PREFERENCE(),
    data,
    handleTokenHeaders({ token })
  );
  return response;
};

export const removeUserNotificationPreferences = async (
  userId: number,
  data: { preferences: any[] },
  token: string
) => {
  const response = await baseAPIFactory.patch(
    END_POINTS.REMOVE_USER_NOTIFICATION_PREFERENCE(userId),
    data,
    handleTokenHeaders({ token })
  );
  return response;
};

export const updateAccountLogo = async (data: { formData: any; token: string }) =>
  baseAPIFactory.put(END_POINTS.UPDATE_ACCOUNT_LOGO(), data.formData, {
    ...handleTokenHeaders(data),
  });
