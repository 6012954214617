import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemButton from '@mui/material/ListItemButton';
import CloseIcon from '@mui/icons-material/Close';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Scrollbar from '../../components/scrollbar/scrollbar';
import styles from './notifications-popover.styles';
import formatDate from '../../utils/dateformat';
import {
  getNoRealTimeNotifications,
  updateNotificationsAsRead,
  END_POINTS,
} from '../../api/notifications/notification';
import { useTokenCallbackPromise } from '../../store/hooks';
import appLogger from '../../infrastructure/config/appLogger';
import createEventSource from '../../api/eventSource';

interface Notification {
  record_id: number | string;
  title: string;
  description: string;
  notificationTypeID: number | string;
  createdAt: Date;
  readStatus: boolean;
  totalUnread?: number | null;
}

function renderContent(notification: Notification) {
  const title = (
    <Typography sx={styles.title}>
      {notification.title}
      <Typography component="span" sx={[styles.title, styles.headerTypography]}>
        &nbsp; {notification.description}
      </Typography>
    </Typography>
  );

  return { title };
}

function NotificationItem({ notification }: { notification: Notification }) {
  const { title } = renderContent(notification);

  return (
    <ListItemButton sx={styles.notificationButton(notification.readStatus)}>
      <ListItemText
        primary={title}
        secondary={
          <Typography sx={styles.secondaryText}>
            <AccessTimeIcon sx={styles.timeIcon} />
            {notification.createdAt
              ? formatDate(notification.createdAt.toISOString(), false, true)
              : formatDate(new Date().toISOString(), false, true)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    record_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    createdAt: PropTypes.instanceOf(Date).isRequired,
    readStatus: PropTypes.bool.isRequired,
  }).isRequired,
};

export default function NotificationsPopover() {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [totalUnRead, setTotalUnRead] = useState<number>(0);
  const [open, setOpen] = useState<null | HTMLElement>(null);
  const limit = 10;
  const makeTokenCall = useTokenCallbackPromise();
  const isFetching = useRef(false);
  const popoverContentRef = useRef<HTMLDivElement>(null);
  const [popoverNode, setPopoverNode] = useState<HTMLDivElement | any>(null);
  const offsetRef = useRef(0);
  const eventSourceRef = useRef<EventSource | null>(null);
  const isInitialized = useRef(false);
  const [userToken, setUserToken] = useState<string>('');

  useEffect(() => {
    try {
      if (userToken) return;
      makeTokenCall().then((token) => {
        setUserToken(token);
      });
    } catch (error) {
      appLogger.error('Error fetching Token:', {
        error,
      });
    }
  }, []);

  const handleMarkAllAsRead = async () => {
    try {
      const token = await makeTokenCall();

      const unreadRecordIds = notifications
        .filter((notification) => !notification.readStatus)
        .map((notification) => notification.record_id);

      if (unreadRecordIds.length === 0) {
        return;
      }

      await updateNotificationsAsRead({ record_ids: unreadRecordIds }, token);

      setNotifications(
        notifications.map((notification) => ({
          ...notification,
          readStatus: true,
          totalUnread: 0,
        }))
      );

      setTotalUnRead(0);
    } catch (error) {
      appLogger.error('Error marking notifications as read:', { error });
    }
  };

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => setOpen(null);

  const fetchNotifications = async () => {
    if (!hasMore || isFetching.current) return;

    isFetching.current = true;

    try {
      const { data } = await getNoRealTimeNotifications(userToken, limit, offsetRef.current);

      if (data.notifications.length === 0) {
        setHasMore(false);
        return;
      }

      const mappedNotifications = data.notifications.map((notification: any) => ({
        record_id: notification.record_id,
        title: notification.title,
        description: notification.description,
        notificationTypeID: notification.notification_type_id,
        createdAt: new Date(notification.created_at),
        readStatus: notification.read_status,
        totalUnread: notification.total_unread,
      }));

      setNotifications((prev) => [...prev, ...mappedNotifications]);

      offsetRef.current += limit;

      if (data.notifications.length < limit) {
        setHasMore(false);
      }
    } catch (error) {
      appLogger.error('Error fetching notifications:', {
        error,
      });
    } finally {
      isFetching.current = false;
    }
  };

  useEffect(() => {
    if (!userToken) return;
    fetchNotifications();
  }, [hasMore, limit, userToken]);

  useEffect(() => {
    if (notifications[0]?.totalUnread) {
      const unReadCount = notifications[0]?.totalUnread || 0;
      setTotalUnRead(Number(unReadCount));
    }
  }, [notifications]);

  const handleScroll = () => {
    const content = popoverContentRef.current;
    if (!content) return;

    const { scrollTop, scrollHeight, clientHeight } = content;
    const isAtBottom = scrollTop + clientHeight >= scrollHeight - 5;

    if (isAtBottom && hasMore && !isFetching.current) {
      fetchNotifications();
    }
  };

  useEffect(() => {
    const content = popoverContentRef.current;

    if (!open || !content) return undefined;

    content.addEventListener('scroll', handleScroll);

    return () => {
      content.removeEventListener('scroll', handleScroll);
    };
  }, [open, hasMore]);

  useEffect(() => {
    if (!userToken) {
      return undefined;
    }
    const initializeEventSource = async () => {
      if (isInitialized.current && eventSourceRef.current) return;
      try {
        const eventSource = createEventSource(END_POINTS.GET_REAL_TIME_NOTIFICATIONS, userToken);
        eventSourceRef.current = eventSource;

        eventSource.onmessage = (event) => {
          const parsedData = JSON.parse(event.data);
          const notification = JSON.parse(parsedData.data);

          if (notification.record_id) {
            setNotifications((prev) => [
              {
                record_id: notification.record_id,
                title: notification.title,
                description: notification.description,
                notificationTypeID: notification.notification_type_id,
                createdAt: notification.created_at ? new Date(notification.created_at) : new Date(),
                readStatus: notification.read_status ?? false,
              },
              ...prev,
            ]);

            setTotalUnRead((prev) => {
              const isUnread = !notification.read_status;
              const newCount = prev + (isUnread ? 1 : 0);
              return newCount;
            });
          }
        };

        eventSource.onerror = (error) => {
          appLogger.error('EventSource error', { error });
          if (eventSourceRef.current) {
            eventSourceRef.current.close();
          }
          eventSourceRef.current = null;
          isInitialized.current = false;
        };
      } catch (error) {
        appLogger.error('Failed to initialize EventSource:', { error });
        isInitialized.current = false;
      }
    };

    if (!isInitialized.current) {
      initializeEventSource().then(() => {
        isInitialized.current = true;
      });
    }

    return () => {
      if (eventSourceRef.current) {
        isInitialized.current = false;
        eventSourceRef.current.close();
        eventSourceRef.current = null;
      }
    };
  }, [userToken]);

  const unreadNotifications = notifications.filter((notification) => !notification.readStatus);
  const readNotifications = notifications.filter((notification) => notification.readStatus);

  return (
    <>
      <IconButton color={open ? 'primary' : 'default'} onClick={handleOpen}>
        <Badge badgeContent={totalUnRead} color="error">
          <NotificationsActiveIcon style={styles.activeIcon} />
        </Badge>
      </IconButton>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{
          paper: {
            sx: {
              overflow: 'visible',
              maxHeight: 'none',
            },
          },
        }}
        TransitionProps={{
          onEntering: (node) => {
            const scrollableNode = node.querySelector('[style*="overflow-y: auto"]') || node;

            const scrollableChildNode = scrollableNode.childNodes[0];
            if (scrollableChildNode) {
              setPopoverNode(scrollableChildNode);
              scrollableChildNode.addEventListener('scroll', handleScroll);
            } else {
              appLogger.error('No scrollable child node found.');
            }
          },
          onExited: () => {
            if (popoverNode) {
              popoverNode.removeEventListener('scroll', handleScroll);
              setPopoverNode(null);
            }
          },
        }}
      >
        <Box sx={styles.scrollBox} ref={popoverContentRef}>
          <Box sx={styles.headerBox}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography>Notifications</Typography>
              <Typography sx={styles.headerTypography}>
                {notifications.length > 0
                  ? `You have ${totalUnRead} unread message${totalUnRead === 1 ? '' : 's'}`
                  : null}
              </Typography>
            </Box>

            {totalUnRead > 0 && (
              <Tooltip title="Mark all as read">
                <IconButton color="primary" onClick={handleMarkAllAsRead}>
                  <DoneAllIcon />
                </IconButton>
              </Tooltip>
            )}
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Divider sx={styles.dividerDashed} />
          <Scrollbar sx={styles.scrollBarHeight}>
            {unreadNotifications.length > 0 && (
              <List disablePadding subheader={<ListSubheader sx={styles.unreadListSubheader}>New</ListSubheader>}>
                {unreadNotifications.map((notification) => (
                  <NotificationItem key={notification.record_id} notification={notification} />
                ))}
              </List>
            )}
            {readNotifications.length > 0 && (
              <List
                disablePadding
                subheader={
                  unreadNotifications.length > 0 ? (
                    <ListSubheader disableSticky sx={styles.unreadListSubheader}>
                      Before that
                    </ListSubheader>
                  ) : null
                }
              >
                {readNotifications.map((notification) => (
                  <NotificationItem key={notification.record_id} notification={notification} />
                ))}
              </List>
            )}
            {notifications.length === 0 && (
              <Box sx={styles.emptyStateBox}>
                <Typography sx={styles.headerTypography}>You don’t have any notifications yet.</Typography>
              </Box>
            )}
          </Scrollbar>
        </Box>
      </Popover>
    </>
  );
}
