import React, { forwardRef, ReactNode } from 'react';
import { Box, useTheme } from '@mui/material';
import customStyles from './styles';
import useIsMobile from './useIsMobile';

interface ScrollbarProps {
  children: ReactNode;
  sx?: object;
  [key: string]: any; // Allow other props
}

const Scrollbar = forwardRef<HTMLDivElement, ScrollbarProps>(({ children, sx, ...other }, ref) => {
  const isMobile = useIsMobile();
  const theme = useTheme();
  const styles = customStyles(theme);

  if (isMobile) {
    return (
      <Box ref={ref} sx={{ ...styles.mobileBox, ...sx }} {...other}>
        {children}
      </Box>
    );
  }

  return (
    <Box sx={styles.rootScrollbar}>
      <Box ref={ref} sx={{ ...styles.scrollbar, ...sx }} {...other}>
        {children}
      </Box>
    </Box>
  );
});

export default Scrollbar;
