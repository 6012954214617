import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import customStyles from './headerMenu.styles';
import Chat from '../header/chat.component';
import NotificationsPopover from '../header/notifications-popover';
import { getStringFeatureFlagValue } from '../../utils/LaunchDarklyQuery';
import useRoleIdentity from '../../components/roles/hooks';
import { PAGE_NAMES, HEADER_MENU_SUB_MODULE_MAP } from '../../components/roles/constants/index';

const HeaderMenu = () => {
  const theme = useTheme();
  const styles = customStyles(theme);
  const [notificationComponent, setNotificationComponent] = useState('');
  const roleIdentity = useRoleIdentity(PAGE_NAMES.HEADER_MENU);

  useEffect(() => {
    const fetchFeatureFlag = async () => {
      const flagValue = await getStringFeatureFlagValue('notifications-dream');
      setNotificationComponent(flagValue || '');
    };

    fetchFeatureFlag();
  }, []);

  const renderContent = (
    <>
      <Box sx={{ ...styles.renderContentBox, ...styles.pointerEvents }} />
      <Stack direction="row" alignItems="center" spacing={1} sx={styles.pointerEvents}>
        {roleIdentity.canModify(HEADER_MENU_SUB_MODULE_MAP.NOTIFICATIONS) && (
          <>
            {notificationComponent && <NotificationsPopover />}
            <Chat />
          </>
        )}
      </Stack>
    </>
  );

  return (
    <AppBar sx={{ ...styles.appBar }}>
      <Toolbar sx={styles.toolbar}>{renderContent}</Toolbar>
    </AppBar>
  );
};

export default HeaderMenu;
