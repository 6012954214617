import baseAPIFactory, { handleTokenHeaders } from '../base';

export interface NotificationData {
  record_id: number | string;
  title: string;
  description: string;
  readStatus: boolean;
  userID: number | null;
  accountNumber: number;
  notificationTypeID: number;
}

export const END_POINTS = {
  GET_NO_REAL_TIME_NOTIFICATIONS: (limit: number, offset: number) =>
    `notification/getNoLiveByAccountOrUserID/?limit=${limit}&offset=${offset}`,
  GET_REAL_TIME_NOTIFICATIONS: 'notification/getLiveByAccountOrUserID',
  UPDATE_NOTIFICATIONS_AS_READ: () => 'notification/markAsRead',
};

export const getNoRealTimeNotifications = async (token: string, limit: number, offset: number) =>
  baseAPIFactory.get(END_POINTS.GET_NO_REAL_TIME_NOTIFICATIONS(limit, offset), handleTokenHeaders({ token }));

export const updateNotificationsAsRead = async (data: any, token: string) =>
  baseAPIFactory.put(END_POINTS.UPDATE_NOTIFICATIONS_AS_READ(), data, handleTokenHeaders({ token }));
