import { configureStore } from '@reduxjs/toolkit';
import accountSlice from '../slices/accountSlice';
import buildingSlice from '../slices/buildingSlice';
import commonSlice from '../slices/commonSlice';
import roadMapSlice from '../slices/roadMapSlice';
import deviceSlice from '../slices/deviceSlice';
import directorySlice from '../slices/gac/directorySlice';
import externalUserSlice from '../slices/externalUserSlice';
import repairSlice from '../slices/repairSlice';
import resourcesSlice from '../slices/resourcesSlice';
import externalUserRoleSlice from '../slices/externalUserRoleSlice';
import fleetImportSlice from '../slices/fleetImportSlice';
import studentSlice from '../slices/studentSlice';
import resourceSlice from '../slices/resourceSlice';
import partClosetSlice from '../slices/partClosetSlice';
import repairDescriptionSlice from '../slices/repairDescription';
import testRepairSlice from '../slices/testRepair';
import bookmarkSlice from '../slices/bookmarkSlice';
import invoicesOrdersSlice from '../slices/invoicesOrdersSlice';

export const store = configureStore({
  reducer: {
    common: commonSlice,
    roadmap: roadMapSlice,
    building: buildingSlice,
    account: accountSlice,
    device: deviceSlice,
    directory: directorySlice,
    externalUser: externalUserSlice,
    repair: repairSlice,
    resources: resourcesSlice,
    fleetImport: fleetImportSlice,
    externalUserRole: externalUserRoleSlice,
    student: studentSlice,
    resource: resourceSlice,
    partCloset: partClosetSlice,
    repairDescription: repairDescriptionSlice,
    testRepair: testRepairSlice,
    bookmark: bookmarkSlice,
    invoicesOrders: invoicesOrdersSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
