import baseAPIFactory, { handleTokenHeaders } from './base';

export type FetchParamTypes = {
  token?: string;
};
export interface PartCloset {
  account: number;
  part_name: string;
  sku: string;
  partid: number;
  quanity: number;
  manufacturer: string;
  parent_device: string;
  part_category: string;
}

export interface Device {
  record_id: number | string;
  device_name: string;
  name: string;
  model: string;
  sku: string;
  manufacturer: Manufacturer;
  price: number | string;
  ram: string;
  screen_size: number;
  cpu: string;
  storage_size: number;
  quantity: number;
  os: string;
  aue: string | Date;
  twoinone: boolean;
  color: string;
  last_modified_by: number;
  last_modified_date: string;
  created_by: number;
  customer_visible_buyback_price: number;
}

export interface Manufacturer {
  recordId: number;
  name: string;
  abbreviation: string;
  website: string;
}

export interface CreatePartCloset {
  quantity: number;
}

const END_POINTS = {
  GET_PART_CLOSET: (itemId?: number) => `part_closet/get_parts/${itemId}`,
  GET_ALL_CUSTOM_PARTS: (accountNum: string | number) => `custom_part/account/${accountNum}`,
  UPDATE_CUSTOM_QUANTITY: (partId: string | number) => `custom_part/${partId}`,
  UPDATE_PART_QUANTITY: (accountId: string | number, partId: string | number) => `part_closet/${accountId}/${partId}`,
  DECREASE_PART_QUANTITY: (accountId: string | number, partId: string | number) =>
    `part_closet/decrease/${accountId}/${partId}`,
  GET_ALL_PART: () => `part/all/`,
  GET_ALL_BY_DEVICE: (serialId: string | number) => `part/compatible/${serialId}`,
  CREATE_NEW_PART_CLOSET: (accountId: string | number, partId: string | number) => `part_closet/${accountId}/${partId}`,
  CREATE_NEW_CUSTOM_PART: () => `/custom_part/`,
  POST_BULK_PART: () => '/custom_part/customBulk',
  GET_ALL_DEVICES: () => `/device/getAllWithParts/`,
  GET_ALL_SKU: () => `/device/allSku/`,
  GET_ALL_BY_PARENT_DEVICE: (deviceNumber: string | number, accountId: string | number, buildingId: string | number) =>
    `part/device/${deviceNumber}/${accountId}/${buildingId}`,
  DELETE_PART_CLOSET: (accountId: string | number, partId: string | number) => `part_closet/${accountId}/${partId}`,
  DELETE_CUSTOM_PART: (partId: string | number) => `custom_part/${partId}`,
  GET_ALL_PARTS: (accountId: string | number) => `part_closet/${accountId}/getAllParts`,
};

export const getAllDevices = async ({ token }: { token?: string }) =>
  baseAPIFactory.get<Device[]>(END_POINTS.GET_ALL_DEVICES(), handleTokenHeaders({ token }));

export const getAllSkus = async ({ token }: { token?: string }) =>
  baseAPIFactory.get<string[]>(END_POINTS.GET_ALL_SKU(), handleTokenHeaders({ token }));

export const getAllCustomParts = async ({ accountNum, token }: { accountNum: string | number; token?: string }) =>
  baseAPIFactory.get<any[]>(END_POINTS.GET_ALL_CUSTOM_PARTS(accountNum), handleTokenHeaders({ token }));

export const getAllPartCloset = async ({
  accountID,
  token,
  extraParams = null,
}: {
  accountID?: number;
  token?: string;
  extraParams?: any;
}) => {
  const extraHeaders: { [key: string]: any } = {};
  if (extraParams !== null) {
    extraHeaders.params = extraParams;
  }
  return baseAPIFactory.get<PartCloset[]>(END_POINTS.GET_PART_CLOSET(accountID), {
    ...extraHeaders,
    ...handleTokenHeaders({ token }),
  });
};

export const getAllCompatible = async ({ serialId, token }: { serialId: string | number; token?: string }) =>
  baseAPIFactory.get<PartCloset[]>(END_POINTS.GET_ALL_BY_DEVICE(serialId), handleTokenHeaders({ token }));

export const getAllCompatibleByDevice = async ({
  deviceId,
  accountId,
  buildingId,
  token,
}: {
  deviceId: string | number;
  accountId: string | number;
  buildingId: string | number;
  token?: string;
}) =>
  baseAPIFactory.get<PartCloset[]>(
    END_POINTS.GET_ALL_BY_PARENT_DEVICE(deviceId, accountId, buildingId),
    handleTokenHeaders({ token })
  );

export const getAllParts = async ({ token }: { token?: string }) =>
  baseAPIFactory.get<PartCloset[]>(END_POINTS.GET_ALL_PART(), handleTokenHeaders({ token }));

export const getAllPartsByDevice = async ({ deviceId, token }: { deviceId: string | number; token?: string }) =>
  baseAPIFactory.get<PartCloset[]>(END_POINTS.GET_ALL_BY_DEVICE(deviceId), handleTokenHeaders({ token }));

export const deletePartCloset = async ({
  accountId,
  partId,
  token,
}: {
  accountId;
  partId: string | number;
  token?: string;
}) => baseAPIFactory.delete<any[]>(END_POINTS.DELETE_PART_CLOSET(accountId, partId), handleTokenHeaders({ token }));
export const deleteCustomPart = async ({ partId, token }: { partId: string | number; token?: string }) =>
  baseAPIFactory.delete<any[]>(END_POINTS.DELETE_CUSTOM_PART(partId), handleTokenHeaders({ token }));

export const updateCustomQuantity = async ({
  partId,
  token,
  data,
}: {
  accountId: string | number;
  partId: string | number;
  token?: string;
  data: any;
}) => baseAPIFactory.put(END_POINTS.UPDATE_CUSTOM_QUANTITY(partId), data, handleTokenHeaders({ token }));

export const decreasePartQuantity = async ({
  accountId,
  partId,
  token,
}: {
  accountId: string | number;
  partId: string | number;
  token?: string;
}) => baseAPIFactory.get(END_POINTS.DECREASE_PART_QUANTITY(accountId, partId), handleTokenHeaders({ token }));

export const updatePartQuantity = async ({
  accountId,
  partId,
  token,
  data,
}: {
  accountId: string | number;
  partId: string | number;
  token?: string;
  data: any;
}) => baseAPIFactory.put(END_POINTS.UPDATE_PART_QUANTITY(accountId, partId), data, handleTokenHeaders({ token }));

export const createPartCloset = async ({
  accountId,
  partId,
  token,
  data,
}: {
  accountId: string | number;
  partId: string | number;
  token?: string;
  data: any;
}) => baseAPIFactory.post(END_POINTS.CREATE_NEW_PART_CLOSET(accountId, partId), data, handleTokenHeaders({ token }));

export const createCustomPart = async ({ token, data }: { token?: string; data: any }) =>
  baseAPIFactory.post(END_POINTS.CREATE_NEW_CUSTOM_PART(), data, handleTokenHeaders({ token }));

export const createBulkPart = async (data: any, token: string) =>
  baseAPIFactory.post(END_POINTS.POST_BULK_PART(), data, handleTokenHeaders({ token }));

export const getAllPartsByAccount = async ({ accountId, token }: { accountId: string | number; token?: string }) =>
  baseAPIFactory.get<PartCloset[]>(END_POINTS.GET_ALL_PARTS(accountId), handleTokenHeaders({ token }));
