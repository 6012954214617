import React, { createContext, useContext, useState, useMemo } from 'react';
import { Box, useTheme } from '@mui/material';
import styles from './ClickWrapper.styles';

const ProcessingContext = createContext<{
  isProcessing: boolean;
  setIsProcessing: (value: boolean) => void;
} | null>(null);

export const ProcessingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isProcessing, setIsProcessing] = useState(false);

  const contextValue = useMemo(() => ({ isProcessing, setIsProcessing }), [isProcessing]);

  return <ProcessingContext.Provider value={contextValue}>{children}</ProcessingContext.Provider>;
};

export const useProcessing = () => {
  const context = useContext(ProcessingContext);
  if (!context) {
    throw new Error('useProcessing must be used within a ProcessingProvider');
  }
  return context;
};

const ClickDisableWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isProcessing } = useProcessing();
  const theme = useTheme();
  const classes = styles(theme, isProcessing);

  return (
    <Box style={classes.wrapper}>
      <Box style={classes.childrenBox}>{children}</Box>
      {isProcessing && <Box style={classes.overlay} />}
    </Box>
  );
};

export default ClickDisableWrapper;
