const styles = {
  rootPopover: {
    mt: 1.5,
    ml: 0.75,
    width: 360,
  },
  scrollBox: {
    maxHeight: '600px',
    overflowY: 'auto',
  },
  headerBox: {
    display: 'flex',
    alignItems: 'center',
    py: 2,
    px: 2.5,
  },
  headerTypography: {
    color: 'text.secondary',
    fontSize: '0.875rem',
  },
  title: {
    fontSize: '0.875rem',
  },
  dividerDashed: {
    borderStyle: 'dashed',
  },
  unreadListSubheader: {
    py: 1,
    px: 2.5,
    typography: 'overline',
  },
  notificationButton: (readStatus: boolean) => ({
    py: 1.5,
    px: 2.5,
    mt: '1px',
    ...(!readStatus && {
      bgcolor: 'action.selected',
    }),
  }),
  secondaryText: {
    mt: 0.5,
    display: 'flex',
    alignItems: 'center',
    color: 'text.disabled',
    fontSize: '0.75rem',
  },
  scrollBarHeight: {
    height: { xs: 340, sm: 'auto' },
  },
  emptyStateBox: {
    textAlign: 'center',
    p: 2,
  },
  timeIcon: {
    mr: 0.5,
    width: 16,
    height: 16,
  },
  activeIcon: {
    color: 'primary.main',
  },
};

export default styles;
