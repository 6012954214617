import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { isAPIResponseFailure } from 'src/api/base';
import appLogger from 'src/infrastructure/config/appLogger';
import { RootState } from 'src/store';
import { bookmark, bookmarkList } from 'src/api/bookmark';
import { setLoading } from './commonSlice';

const sliceName = 'bookmark';

export interface InitialState {
  bookmarkList: bookmark[];
  bookmarkSortList: bookmark[];
  bookmarkLoading: boolean;
  bookmarkViewLoading: boolean;
}

const initialState: InitialState = {
  bookmarkList: [],
  bookmarkSortList: [],
  bookmarkLoading: false,
  bookmarkViewLoading: false,
};

type TokenParam = {
  token: string;
};

export const fetchBookmarkItems = createAsyncThunk<bookmark[], TokenParam>(
  `${sliceName}/fetchBookmarkItems`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetchBookmarkItems`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const data = { fetchAll: true, ...params };
      const todoList = await bookmarkList(data);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(todoList)) {
        return ThunkAPI.rejectWithValue(todoList);
      }
      return todoList.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchBookmarks = createAsyncThunk<bookmark[], TokenParam>(
  `${sliceName}/fetchBookmarks`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetchBookmarks`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const data = { startRow: 0, endRow: 5, ...params };
      const todoList = await bookmarkList(data);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(todoList)) {
        return ThunkAPI.rejectWithValue(todoList);
      }
      return todoList.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const AddBookmarks = createAsyncThunk<any[], TokenParam>(
  `${sliceName}/fetchBookmarks`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetchBookmarks`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const data = { startRow: 0, endRow: 5, ...params };
      const todoList = await bookmarkList(data);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(todoList)) {
        return ThunkAPI.rejectWithValue(todoList);
      }
      return todoList.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);
const bookmarkSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBookmarks.pending, (state) => {
      state.bookmarkLoading = true;
    });
    builder.addCase(fetchBookmarks.fulfilled, (state, action) => {
      const { payload } = action;
      state.bookmarkSortList = payload;
      state.bookmarkLoading = false;
    });
    builder.addCase(fetchBookmarks.rejected, (state) => {
      state.bookmarkLoading = false;
    });
    builder.addCase(fetchBookmarkItems.pending, (state) => {
      state.bookmarkViewLoading = true;
    });
    builder.addCase(fetchBookmarkItems.fulfilled, (state, action) => {
      const { payload } = action;
      state.bookmarkList = payload;
      state.bookmarkViewLoading = false;
    });
    builder.addCase(fetchBookmarkItems.rejected, (state) => {
      state.bookmarkViewLoading = false;
    });
  },
});

export default bookmarkSlice.reducer;

export const bookmarkStateItem = createSelector(
  (state: RootState) => state.bookmark,
  (items: InitialState) => items
);
