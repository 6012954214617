import baseAPIFactory, { handleTokenHeaders } from '../base';
import { Device } from '../partCloset';
import { Student } from '../student';
import { Building } from '../types/building';
import { TransformedQueryResult } from '../types/query';

export interface FleetDevice {
  isLoading?: boolean;
  record_id?: number;
  account_number?: number;
  serial_number?: string;
  loaner_status?: string;
  created_date?: Date;
  created_by?: number;
  last_modified_by?: number;
  additional_info?: string;
  status?: string;
  google_id?: string;
  building?: Building;
  device?: Device;
  base_warranty?: Warranty;
  advanced_warranty?: Warranty;
  current_warranty?: Warranty;
  student?: Student;
  asset_tag?: string;
  organizational_unit?: string;
  is_verified?: boolean;
  current_repair_ticket?: RepairTicket;
  device_model?: string;
  device_status?: string;
  last_battery_charge_capacity?: number;
}

export interface RepairTicket {
  record_id: number | null;
}

export interface FleetDeviceTableData {
  isLoading: boolean;
  fleetDevices: FleetDevice[];
}

export interface Warranty {
  record_id: number;
  start_date: string;
  end_date: string;
  provider: WarrantyProvider;
  sku: string;
  type: string;
  description: string;
  damage_protection: string;
  price_per_device: string;
  years: number;
  months: number;
  battery_year: number;
  battery_month: number;
  name: string;
  netsuite_record_id: string;
}

export interface WarrantyProvider {
  record_id: number;
  name: string;
  domain: string;
  email: string;
  phone: string;
  asp_website: string;
}

export interface BuybackRow {
  device_model: string;
  device_name: string;
  device_sku: string;
  device_mpn: string;
  device_customer_visible_buyback_price: number;
  model_count: number;
  total_buyback_value: number;
}

const END_POINTS = {
  FLEET_DEVICE_UPDATE: (accountNumber, key: string | number) => `/customer/${accountNumber}/fleet-device/${key}`,
  FLEET_DEVICE_GET: (accountNumber, key: string | number) => `/customer/${accountNumber}/fleet-device/${key}`,
  FLEET_DEVICE_LIST: (accountNumber) => `/customer/${accountNumber}/fleet-device`,
  FLEET_DEVICE_LIST_AG: (accountNumber) => `/customer/${accountNumber}/fleet-device/ag`,
  FLEET_DEVICE_ADMIN_LIST: () => `fleet_device/admin/list`,
  FLEET_DEVICE_BUYBACK_DATA: (accountNumber) => `/customer/${accountNumber}/buyback-data`,
};
export const getBuybackData = async (accountNumber: number, token: string) =>
  baseAPIFactory.get<BuybackRow[]>(END_POINTS.FLEET_DEVICE_BUYBACK_DATA(accountNumber), {
    ...handleTokenHeaders({ token }),
  });

export const updateFleetDevice = async (
  accountNumber: number,
  key: string | number,
  payload: FleetDevice,
  token: string
) =>
  baseAPIFactory.put<FleetDevice>(
    END_POINTS.FLEET_DEVICE_UPDATE(accountNumber, key),
    payload,
    handleTokenHeaders({ token })
  );

export const getFleetDevice = async (accountNumber: number, key: string | number, token: string) =>
  baseAPIFactory.get<FleetDevice>(END_POINTS.FLEET_DEVICE_GET(accountNumber, key), handleTokenHeaders({ token }));

export const getFleetDeviceList = async (accountNumber: number, params: any, token: string) =>
  baseAPIFactory.get<TransformedQueryResult>(END_POINTS.FLEET_DEVICE_LIST(accountNumber), {
    params,
    ...handleTokenHeaders({ token }),
  });

export const getFleetDeviceListAG = async (accountNumber: number, params: any, token: string) =>
  baseAPIFactory.get<TransformedQueryResult>(END_POINTS.FLEET_DEVICE_LIST_AG(accountNumber), {
    params,
    ...handleTokenHeaders({ token }),
  });

export const getDeviceAdminList = async (params: any, token: string) =>
  baseAPIFactory.get<TransformedQueryResult>(END_POINTS.FLEET_DEVICE_ADMIN_LIST(), {
    params,
    ...handleTokenHeaders({ token }),
  });
