import Colors from '../../constants/colors/colors.constant';

export type direction = 'column' | 'row';

export const DialogModalActions = () => ({
  display: 'flex',
  px: 2,
  pt: 2,
  pb: 0,
  mb: 0,
});

export const DialogModalAction = () => ({
  width: '100%',
  mb: 2,
});

export const DialogModalActionMargin = (direction: direction) => {
  if (direction === 'column') return { ml: '0 !important' };
  return {};
};

export const DialogModalTitle = (theme: any) => ({
  ml: 2,
  mb: 2,
  flex: 1,
  top: theme.spacing(1),
  position: 'relative',
  textAlign: 'center',
  color: Colors.primary.headerTitle,
});

export const DialogModalCloseIcon = () => ({
  backgroundColor: Colors.primary.Solitude,
  color: Colors.primary.Manatee,
  marginRight: '-10px',
  width: '31px',
  height: '31px',
});

export const DialogModalContent = () => ({
  minWidth: '800px',
});

export const DialogModalCustomButton = () => ({
  width: '250%',
  mb: 2,
  display: 'flex',
  alignItems: 'center',
});
